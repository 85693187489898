import { TextareaAutosize, styled } from '@mui/material';
import palette from 'theme/palette';

const StyledDivTextarea = styled('div')(({ theme }) => ({
  width: '100%',
  marginTop: '10px',
  fontFamily: 'Roboto',
  borderWidth: '1px',
  borderRadius: '0.5rem',
  backgroundColor: 'transparent !important',
  border: '1px solid transparent',
  borderColor: '#919eab52',
  transition: 'all .3s',
  fontSize: '14px',
  lineHeight: '1.5',
  fontWeight: 400,
  padding: '12px 12px 8px',
  outline: 'none',
  whiteSpace: 'pre-wrap',
  '&:focus': {
    borderColor: palette.active,
    borderWidth: '1px',
  },
  '&::placeholder': {
    fontFamily: 'Roboto',
    color: 'rgb(145, 158, 171)',
    fontSize: '13px',
    fontWeight: '500',
  },
}));

const StyledButtonFormat = styled('button')(() => ({
  cursor: 'pointer',
  border: 'none',
  fontSize: '13px',
  padding: 5,
  width: 45,
  borderRadius: '2px',
  backgroundColor: 'rgba(0, 0, 0, 0.1)',
  fontWeight: 500,
  opacity: 0.7,
  transition: 'all .3s',
  '&:hover': {
    boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.5)',
  },
}));

const StyledTextarea = styled(TextareaAutosize)(({ theme }) => ({
  resize: 'none',
  width: '100%',
  fontFamily: 'Roboto',
  borderWidth: '1px',
  borderRadius: '0.5rem',
  backgroundColor: 'transparent !important',
  border: '1px solid transparent',
  borderColor: '#919eab52',
  transition: 'all .3s',
  fontSize: '14px',
  lineHeight: '1.5',
  fontWeight: 400,
  padding: '12px 12px 8px',
  height: '1em',
  outline: 'none',
  '&:focus': {
    borderColor: palette.active,
    borderWidth: '1px',
  },
  '&::placeholder': {
    fontFamily: 'Roboto',
    color: 'rgb(145, 158, 171)',
    fontSize: '13px',
    fontWeight: '500',
  },
  '&.Mui-focused': {
    color: palette.text,
    opacity: '0.7',
    transform: 'translate(12px, 8px) scale(0.85)',
  },
  '&.MuiFormLabel-root.Mui-focused': {
    transform: 'translate(12px, 8px) scale(0.85)',
  },
  '&.MuiInputLabel-filled': {
    transform: 'translate(12px, 8px) scale(0.85)',
  },
  '&.MuiOutlinedInput-input': {
    fontWeight: 500,
    color: palette.text,
  },
}));

export { StyledDivTextarea, StyledButtonFormat };
export default StyledTextarea;
