import React from 'react';

import StyledButton, { LoadinButton } from './styled';

const Button = ({ label, overrideStyles, onClick, loading }) => {
  return loading ? (
    <LoadinButton sx={overrideStyles} loading={loading}>
      {label}
    </LoadinButton>
  ) : (
    <StyledButton sx={overrideStyles} onClick={onClick}>
      {label}
    </StyledButton>
  );
};

export default Button;
