import { useRoutes } from 'react-router-dom';
import React from 'react';

import { Dashboard } from 'features/dashboard';
import Shortlist from 'features/shortlist';
import SecondShortlist from 'features/secondShortlist';

const Router = () => {
  const commonRoutes = [
    { path: '/', element: <Dashboard /> },
    { path: '/shortlist', element: <Shortlist /> },
    { path: '/shortlist-second', element: <SecondShortlist /> },
  ];

  const routes = useRoutes(commonRoutes);

  return routes;
};

export default Router;
